import styled from "@emotion/styled/macro";
import {
  inputColor,
  newsBorderColor,
  selectedIntervalColor,
  textColor,
  timeDateColor,
} from "../utils/color";
import { fadeIn } from "../dropdown/dropdownStyle";

export const NewsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid ${newsBorderColor};
  color: white;
  margin: auto;
  max-width: 700px;
  padding: 16px;
  color: ${textColor};

  &:first-of-type {
    border-top: 1px solid ${newsBorderColor};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleText = styled.h3`
  margin-left: 20px;
  color: ${textColor};
  text-decoration: underline;
  margin-top: 15px;
  font-size: 22px;
`;

export const NewHeadlineText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: orange;
  margin: 0;
`;

export const DateTime = styled.span`
  color: ${timeDateColor};
  font-size: 13px;
`;

export const CoinButton = styled.button<{ insertModal?: boolean }>`
  width: 120px;
  height: 40px;
  cursor: pointer;
  background-color: ${inputColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: black;
  font-weight: 550;
  margin-top: ${(props) => (props.insertModal ? "20px" : "5px")};
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 0.5s ease-out;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${selectedIntervalColor};
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.4s ease;
    z-index: 0;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  span {
    z-index: 1;
  }

  label {
    z-index: 1;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  width: 100%;
`;

export const NewsTitle = styled.a`
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${textColor};
  line-height: 1.5;
  margin: 15px 0;

  &:hover {
    color: orange;
  }
`;

export const IconsMenu = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  margin: 0px 20px;
  box-sizing: border-box;
  color: ${textColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MenuPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchBar = styled.input`
  width: 7rem;
  height: 1.8rem;
  background-color: ${textColor};
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  transition: width 0.3s ease;

  &:focus {
    width: 20rem;
  }
`;
