import { toast } from "react-toastify";
import { EndpointUploadResponse } from "../utils/interface";

const handleUploadFile = async (
  file: File
): Promise<EndpointUploadResponse> => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(String(process.env.REACT_APP_UPLOAD), {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errMessage: EndpointUploadResponse = await response.json();
      toast.error(
        `${response.status}: ${response.statusText}. ${errMessage.message}`
      );
      console.log("error: ", errMessage.message);
      // console.log("uploadddd: ", response);
      throw new Error(
        `Failed uploading file: , ${response.status}, ${response.statusText}`
      );
    }
    return response.json();
  } catch (err) {
    console.error("Error uploading file: ", err);
    throw err;
  }
};

export default handleUploadFile;
