import { css } from "@emotion/react";
import { backgroundColor, darkGray, timeDateColor } from "../utils/color";

const GlobalStyle = css`
  body {
    font-family: "Clarkson", Helvetica, sans-serif;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${backgroundColor};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${timeDateColor};
    border-radius: 6px;

    &:hover {
      background-color: ${darkGray};
    }
  }
`;

export default GlobalStyle;
