import {
  DbDataInsert,
  HeaderObject,
  InsertModalProps,
} from "../utils/interface";
import { groupPairsArray, validateTimestamp } from "../utils/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import InsertField from "./insertField";
import handleInsertData from "../services/insertData";
import { toast } from "react-toastify";

const InsertModal = ({
  isInsertModalOpen,
  closeInsertModal,
  setNewsData,
  newsData,
}: InsertModalProps) => {
  const initialFormData = useMemo(
    () => ({
      timestamp: "",
      coins: "",
      category: "",
      title: "",
      source: "",
      news_source: "",
      url: "",
      info: "",
    }),
    []
  );
  const [categorySubmit, setCategorySubmit] = useState<string | null>(null);
  const [insertData, setInsertData] = useState<DbDataInsert>(initialFormData);
  const [userInsertDataInput, setUserInsertDataInput] =
    useState<DbDataInsert>(initialFormData);

  const headersObject: HeaderObject = {
    Timestamp: "timestamp",
    Coins: "coins",
    Category: "category",
    Title: "title",
    Source: "source",
    "News Source": "news_source",
    Url: "url",
    Info: "info",
  };

  const handleInsertDataSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateTimestamp(Number(userInsertDataInput.timestamp))) {
      toast.warn("Timestamp must be a valid UNIX in ms");
      return;
    }
    setInsertData(userInsertDataInput);
    setNewsData([...newsData, userInsertDataInput]);
    console.log("submit: ", userInsertDataInput);
  };

  const handleInsertDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name.toLowerCase() === "timestamp" && !/^\d*$/.test(value)) return;
    const headerKey = headersObject[name];
    setUserInsertDataInput({ ...userInsertDataInput, [headerKey]: value });
    // console.log("name: ", name, "value: ", value, typeof value);
  };

  const headers = Object.keys(headersObject);
  const pairHeaders = groupPairsArray(headers);

  const sendInsertData = useCallback(
    async (data: DbDataInsert): Promise<void> => {
      try {
        const response = await handleInsertData(data);
        if (response.success) {
          toast.success(response.message);
          console.log(response.success);
          setUserInsertDataInput(initialFormData);
          setCategorySubmit(null);
        } else if (response.warning) {
          toast.warn(response.message);
          console.log(response.message);
        } else {
          console.log("response: ", response);
          toast.error(response.message);
        }
      } catch (err) {
        console.error("Error sending insert data: ", err);
      }
    },
    [initialFormData]
  );

  useEffect(() => {
    console.log("insert triggering: ", insertData);
    if (insertData && insertData.title) {
      sendInsertData(insertData);
    }
  }, [insertData, sendInsertData]);

  return (
    <InsertField
      isInsertModalOpen={isInsertModalOpen}
      closeInsertModal={closeInsertModal}
      handleInsertDataSubmit={handleInsertDataSubmit}
      pairHeaders={pairHeaders}
      handleInsertDataChange={handleInsertDataChange}
      setUserInsertDataInput={setUserInsertDataInput}
      userInsertDataInput={userInsertDataInput}
      headersObject={headersObject}
      categorySubmit={categorySubmit}
      setCategorySubmit={setCategorySubmit}
    />
  );
};

export default InsertModal;
