import { keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const DropdownItems = styled.div`
  z-index: 1000000;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
  }
`;

export const MenuButton = styled.button<{
  isSelected: boolean;
  optionMenu?: boolean;
}>`
  flex: 1;
  background-color: ${(props) => (props.isSelected ? "gray" : "white")};
  color: black;
  border: none;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.optionMenu ? "gray" : "none")};
  }
`;

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opcaity: 1;
}
`;

export const DropdownBox = styled.div<{ import?: boolean }>`
  position: absolute;
  top: 100%;
  left: -25%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 10;
  animation: ${fadeIn} 0.3s ease-out;
  width: ${(props) => (props.import ? "150px" : "190px")};
`;

export const CheckMark = styled.span`
  animation: ${fadeIn} 0.5s ease;
`;
