import { BiMenu } from "react-icons/bi";
import { useEffect, useRef } from "react";
import DropdownMenuOptions from "../dropdown/dropdownMenuOptions";
import { MenuDropdownProps } from "../utils/interface";

const MenuDropdown = ({
  dropdownCategoryOpened,
  setDropdownCategoryOpened,
  dropdownMenuOpened,
  setDropdownMenuOpened,
  openInsertModal,
  openImportModal,
}: MenuDropdownProps) => {
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const toggleMenuDropdown = () => {
    if (dropdownCategoryOpened) setDropdownCategoryOpened(false);
    setDropdownMenuOpened(!dropdownMenuOpened);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(e.target as Node)
      ) {
        setDropdownMenuOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setDropdownMenuOpened]);

  return (
    <>
      <div style={{ position: "relative" }} ref={dropdownMenuRef}>
        <BiMenu
          style={{ fontSize: 33, marginLeft: 13, marginTop: 10 }}
          onClick={toggleMenuDropdown}
        />
        {dropdownMenuOpened ? (
          <DropdownMenuOptions
            openInsertModal={openInsertModal}
            openImportModal={openImportModal}
          />
        ) : null}
      </div>
    </>
  );
};

export default MenuDropdown;
