import { ColorType } from "lightweight-charts";
import {
  chartBackgroundColor,
  downColor,
  gridColor,
  textColor,
  upColor,
} from "./color";
import { IntervalKey, StartEndTime } from "./interface";

export const chartStylesOptions = {
  crosshair: {
    mode: 0,
  },
  layout: {
    background: {
      type: ColorType.Solid,
      color: chartBackgroundColor,
    },
    textColor: textColor,
  },
  grid: {
    vertLines: {
      color: gridColor,
    },
    horzLines: {
      color: gridColor,
    },
  },
  timeScale: {
    secondsVisible: true,
    // rightOffset: 5,
    timeVisible: true,
  },
};

export const candlestickStylesOption = {
  upColor: upColor,
  downColor: downColor,
  wickUpColor: upColor,
  wickDownColor: downColor,
  borderVisible: false,
};

export const timeframeOptions = [
  "1m",
  "5m",
  "15m",
  "1h",
  "4h",
  "12h",
  "1d",
  "1w",
  "1M",
];

export const categoryOptions = [
  "1. Exchanges",
  "2. Partnerships",
  "3. Investments",
  "4. Governance",
  "5. Legal",
  "6. TradFi Products",
  "7. Crypto Payments",
  "8. Macroeconomic Data",
  "9. Other",
];

export const calculateFutureTime = (
  currentTimestamp: number,
  interval: string
): number => {
  try {
    const intervalTimeframe: { [key in IntervalKey]: number } = {
      "1m": 60,
      "3m": 180,
      "5m": 300,
      "15m": 900,
      "30m": 1800,
      "1h": 3600,
      "2h": 7200,
      "4h": 14400,
      "6h": 21600,
      "8h": 28800,
      "12h": 43200,
      "1d": 86400,
      "3d": 259200,
      "1w": 604800,
      "1M": 2592000,
    };
    const timeToAdd = intervalTimeframe[interval as IntervalKey] || 3600;
    return currentTimestamp + timeToAdd * 10;
  } catch (err) {
    console.error("Fail calculating future time: ", err);
    throw err;
  }
};

export const getIntervalStartTime = (interval: number): Date => {
  try {
    const now = new Date();
    return new Date(Math.floor(now.getTime() / interval) * interval);
  } catch (err) {
    console.error("Failed getting interval start time: ", err);
    throw err;
  }
};

export const getTradeTime = (date: number, interval: string): number => {
  const unit = interval.slice(-1);
  const value = parseInt(interval.slice(0, -1), 10);
  const newDate = new Date(date);
  const tradeTime = date / 1000;

  switch (unit) {
    case "m":
      return tradeTime - (tradeTime % (value * 60));
    case "h":
      return tradeTime - (tradeTime % (value * 3600));
    case "d":
      return tradeTime - (tradeTime % (value * 86400));
    case "w": {
      const day = newDate.getUTCDay();
      const diff = newDate.getUTCDate() - day + (day === 0 ? -6 : 1);
      newDate.setUTCDate(diff);
      newDate.setUTCHours(0, 0, 0, 0);
      return newDate.getTime() / 1000;
    }
    case "M":
      newDate.setUTCDate(1);
      newDate.setUTCHours(0, 0, 0, 0);
      return newDate.getTime() / 1000;
    default:
      throw new Error(`Unsupported interval: ${interval}`);
  }
};

export const groupPairsArray = (array: string[]): string[][] => {
  try {
    const pairArray = [];
    for (let i = 0; i < array.length; i += 2) {
      pairArray.push(array.slice(i, i + 2));
    }
    return pairArray;
  } catch (err) {
    console.error("Failed grouping array: ", err);
    throw err;
  }
};

export const validateTimestamp = (timestamp: number): boolean => {
  try {
    if (isNaN(timestamp)) {
      return false;
    }
    const timestampString = timestamp.toString();
    if (timestampString.length !== 13) {
      return false;
    }
    const timestampDate = new Date(timestamp);
    if (timestampDate < new Date("1970-01-01")) {
      return false;
    }
    return true;
  } catch (err) {
    console.error("Failed validating timestamp: ", err);
    throw err;
  }
};

export const getStartEndTime = (
  interval: string,
  timestamp: string
): StartEndTime => {
  try {
    // console.log("get timestamp: ", timestamp, interval);
    const candleBefore = 150; //originally 300
    const candleAfter = 600; //originally 1200 but it will mess with visible range if all data can't fit
    const intervalDuration = convertIntervalToMs(interval);
    // console.log("interval duration: ", intervalDuration);
    const startTime = Number(timestamp) - candleBefore * intervalDuration;
    const endTime = Number(timestamp) + candleAfter * intervalDuration;
    // console.log("get timestamp stend: ", startTime, endTime);
    return { startTime, endTime };
  } catch (err) {
    console.error("Failed getting start and end time: ", err);
    throw err;
  }
};

export const convertIntervalToMs = (interval: string): number => {
  try {
    switch (interval) {
      case "1m":
        return 60 * 1000;
      case "5m":
        return 5 * 60 * 1000;
      case "15m":
        return 15 * 60 * 1000;
      case "1h":
        return 60 * 60 * 1000;
      case "4h":
        return 4 * 60 * 60 * 1000;
      case "12h":
        return 12 * 60 * 60 * 1000;
      case "1d":
        return 24 * 60 * 60 * 1000;
      case "1w":
        return 7 * 24 * 60 * 60 * 1000;
      case "1M":
        return 30 * 24 * 60 * 60 * 1000;
      default:
        return -1;
    }
  } catch (err) {
    console.error("Error converting interval to ms: ", err);
    throw err;
  }
};
