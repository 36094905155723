import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Homepage from "./homepage/homepage";
import { Global } from "@emotion/react";
import GlobalStyle from "./globalStyle/globalStyle";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

function App() {
  return (
    <div>
      <Global styles={GlobalStyle} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        theme="dark"
      />
      <Homepage />
    </div>
  );
}

export default App;
