import React, { useEffect, useRef, useState } from "react";
import {
  IChartApi,
  ISeriesApi,
  SeriesMarkerPosition,
  SeriesMarkerShape,
  Time,
  UTCTimestamp,
  createChart,
} from "lightweight-charts";
import { BinanceKlineData } from "../klineData/kline";
import { ChartComponentProps } from "../utils/interface";
import {
  candlestickStylesOption,
  chartStylesOptions,
  convertIntervalToMs,
  getStartEndTime,
  timeframeOptions,
} from "../utils/utils";
import {
  Chart,
  ChartContainer,
  InputContainer,
  IntervalButton,
} from "./chartMenuStyle";
import TickerInputDropdown from "./tickerDropdown";

const ChartComponent = ({
  defaultCoin,
  setCoin,
  coin,
  timestamp,
  klineData,
  setKlineData,
  setTimestamp,
  isAutoComplete,
  setIsAutoComplete,
}: ChartComponentProps) => {
  const defaultInterval = "15m";
  const chartContainer = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<IChartApi | null>(null);
  const candlestickSeries = useRef<ISeriesApi<"Candlestick"> | null>(null);
  const [inputValue, setInputValue] = useState<string>(defaultCoin);
  const [interval, setInterval] = useState<string>(defaultInterval);

  const handleIntervalSubmit = (intervalValue: string) => {
    if (intervalValue === interval) return;
    setKlineData([]);
    setInterval(intervalValue);
    // console.log("value: ", intervalValue);
  };

  useEffect(() => {
    if (chartContainer.current && !chartInstance.current) {
      chartInstance.current = createChart(
        chartContainer.current,
        chartStylesOptions
      );
      chartInstance.current.timeScale().applyOptions({
        timeVisible: true,
        secondsVisible: true,
        // rightOffset: 20,
      });
      candlestickSeries.current = chartInstance.current.addCandlestickSeries();
      candlestickSeries.current.applyOptions({
        priceFormat: {
          type: "price",
          precision: 4,
          minMove: 0.0001,
        },
      });
      // console.log("chart timestamp: ", Number(timestamp) / 1000);
    }

    // if (chartInstance.current && klineData.length > 0) {
    //   const lastCandleTime = klineData[klineData.length - 1].time;
    //   const futureTime = calculateFutureTime(Number(lastCandleTime), interval);
    //   chartInstance.current.timeScale().setVisibleRange({
    //     from: klineData[0].time,
    //     to: futureTime as UTCTimestamp,
    //   });
    // }

    return () => {
      console.log("cleanup");
      if (chartInstance.current) {
        chartInstance.current.remove();
        chartInstance.current = null;
        candlestickSeries.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (
      candlestickSeries.current &&
      chartInstance.current &&
      klineData.length > 0
    ) {
      candlestickSeries.current.setData(klineData);
      candlestickSeries.current.applyOptions(candlestickStylesOption);
      if (!timestamp) candlestickSeries.current.setMarkers([]);
      if (timestamp && klineData.length > 0) {
        console.log("timestamp: ", timestamp);
        console.log("interval: ", convertIntervalToMs(interval));
        const { startTime, endTime } = getStartEndTime(interval, timestamp);
        const fromTime = startTime / 1000;
        const toTime = endTime / 1000;
        const markers = [
          {
            time: ((Number(timestamp) - convertIntervalToMs(interval)) / 1000) as Time,
            position: "aboveBar" as SeriesMarkerPosition,
            color: "#f68410",
            shape: "circle" as SeriesMarkerShape,
            text: "Timestamp",
          },
        ];
        chartInstance.current.timeScale().setVisibleRange({
          from: fromTime as UTCTimestamp,
          to: toTime as UTCTimestamp,
        });
        candlestickSeries.current.setMarkers(markers);
        const range = chartInstance.current.timeScale().getVisibleRange();
        console.log("current visible range: ", range);
      }
    }
  }, [interval, isAutoComplete, klineData, timestamp]);

  return (
    <>
      <BinanceKlineData
        setKlineData={setKlineData}
        coin={coin}
        interval={interval}
        timestamp={timestamp}
      />
      {/* <AggTradeWebsocket
        candlestickSeries={candlestickSeries.current}
        coin={coin}
        interval={interval}
        klineData={klineData}
        setKlineData={setKlineData}
      /> */}
      <ChartContainer>
        <InputContainer>
          <TickerInputDropdown
            value={inputValue.toUpperCase()}
            setInputValue={setInputValue}
            setCoin={setCoin}
            coin={coin}
            setTimestamp={setTimestamp}
            isAutoComplete={isAutoComplete}
            setIsAutoComplete={setIsAutoComplete}
          />
          {timeframeOptions.map((timeframe) => {
            const isSelected = interval === timeframe;
            return (
              <IntervalButton
                key={timeframe}
                onClick={() => handleIntervalSubmit(timeframe)}
                selected={isSelected}
              >
                {timeframe}
              </IntervalButton>
            );
          })}
        </InputContainer>
        <Chart ref={chartContainer} />
      </ChartContainer>
    </>
  );
};

export default ChartComponent;
