export const backgroundColor = "#1d2021";
export const upColor = "#ffffff";
export const downColor = "#0b89e3";
export const chartBackgroundColor = "#121212";
export const textColor = "#D9D9D9";
export const gridColor = "#333";
export const inputColor = "#a9adc1";
export const selectedIntervalColor = "#626369";
export const buttonBorderColor = "#3a3d4a";
export const newsBorderColor = "#8c8273";
export const timeDateColor = "#cccccc84";
export const darkGray = "#A9A9A9";
