import { useDropzone } from "react-dropzone";
import { DragAndDropContainer, DragAndDropPosition } from "./dragNDropStyle";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useCallback, useEffect, useState } from "react";
import handleUploadFile from "../services/uploadFile";
import {
  DragAndDropComponentProps,
  EndpointUploadResponse,
} from "../utils/interface";
import { toast } from "react-toastify";
import { CoinButton } from "../news/newsStyle";

const DragAndDrop = ({
  closeImportModal,
  fetchAllData,
}: DragAndDropComponentProps) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".numbers",
      ],
    },
    maxFiles: 1,
  });
  const [validFile, setValidFile] = useState<boolean>(false);

  const renderAcceptedFile = acceptedFiles.map((file) => (
    <li key={file.size}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const handleButtonClick = () => {
    closeImportModal();
    setValidFile(false);
  };

  const uploadFileResponse = useCallback(
    async (acceptedFiles: File[]): Promise<void> => {
      try {
        if (acceptedFiles.length > 0) {
          const response: EndpointUploadResponse = await handleUploadFile(
            acceptedFiles[0]
          );
          // console.log("response file: ", response);
          if (response.success) {
            toast.success(response.message);
            setValidFile(true);
            fetchAllData();
            console.log("upload success: ", response.message);
          } else if (response.warning) {
            toast.warn(response.message);
            setValidFile(true);
            console.log("upload warning: ", response.message);
          } else {
            toast.error(response.message);
            setValidFile(false);
            console.log("upload error: ", response);
            return;
          }
        }
      } catch (err) {
        console.error("Error getting upload file response: ", err);
      }
    },
    [fetchAllData]
  );

  useEffect(() => {
    uploadFileResponse(acceptedFiles);
    console.log("accept file: ", acceptedFiles);
    setValidFile(false);
  }, [acceptedFiles, uploadFileResponse]);

  return (
    <DragAndDropPosition parent={true}>
      <h4>Import XLSX File</h4>
      <DragAndDropContainer
        {...getRootProps({ isFocused, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <MdOutlineCloudUpload style={{ fontSize: 40 }} />
        <p>Drag and drop your xlsx file here, or click to select files</p>
      </DragAndDropContainer>
      <aside>
        {validFile && renderAcceptedFile.length > 0 ? (
          <DragAndDropPosition>
            <span>Accepted files:</span>
            <ul>{renderAcceptedFile}</ul>
            <CoinButton onClick={handleButtonClick}>
              <label>Close</label>
            </CoinButton>
          </DragAndDropPosition>
        ) : null}
      </aside>
    </DragAndDropPosition>
  );
};

export default DragAndDrop;
