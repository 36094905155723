import { useCallback, useEffect, useState } from "react";
import {
  ButtonContainer,
  CoinButton,
  DateTime,
  HeaderContainer,
  NewHeadlineText,
  NewsCard,
  NewsTitle,
} from "./newsStyle";
import ImportModal from "../modal/importModal";
import InsertModal from "../modal/insertModal";
import { DbData, EndpointDataResponse, NewsProps } from "../utils/interface";
import NewsHeader from "./newsHeader";
import { toast } from "react-toastify";
import { categoryOptions } from "../utils/utils";

const News = ({ handleNewsButton }: NewsProps) => {
  const [dropdownCategoryOpened, setDropdownCategoryOpened] =
    useState<boolean>(false);
  const [dropdownMenuOpened, setDropdownMenuOpened] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isInsertModalOpen, setIsInsertModalOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [newsData, setNewsData] = useState<DbData[]>([]);
  const [filterNewsData, setFilterNewsData] = useState<DbData[]>(newsData);
  const [selectedItems, setSelectedItems] = useState<string[]>(categoryOptions);

  const openImportModal = () => {
    if (isImportModalOpen) return;
    setIsImportModalOpen(true);
    setDropdownMenuOpened(false);
  };

  const openInsertModal = () => {
    if (isInsertModalOpen) return;
    setIsInsertModalOpen(true);
    setDropdownMenuOpened(false);
  };

  const closeImportModal = () => {
    if (!isImportModalOpen) return;
    setIsImportModalOpen(false);
  };

  const closeInsertModal = () => {
    if (!isInsertModalOpen) return;
    setIsInsertModalOpen(false);
  };

  const handleSearchInput = (title: string) => {
    // console.log("search input: ", title);
    setSearchInput(title);
  };

  useEffect(() => {
    setFilterNewsData(
      newsData.filter((d) =>
        d.title.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [newsData, searchInput]);

  useEffect(() => {
    const categorySelected = selectedItems.map((item) => item.split(".")[0]);
    setFilterNewsData(
      newsData.filter((data) => categorySelected.includes(data.category))
    );
    // console.log("filtering: ", newsData);
  }, [newsData, selectedItems]);

  // console.log("filter data: ", filterNewsData);

  const fetchAllData = useCallback(async () => {
    try {
      const response = await fetch(String(process.env.REACT_APP_DATA));
      const data: EndpointDataResponse = await response.json();
      if (!response.ok) {
        console.error(
          `Failed fetching data:  ${response.status}: ${data.message}`
        );
        toast.error(
          `${response.status}: ${response.statusText}. ${data.message}`
        );
        return;
      }
      if (data.data.length > 0 && data.success) {
        setNewsData(data.data);
        toast.success(data.message);
      }
    } catch (err) {
      console.error("Error fetching data: ", err);
      return;
    }
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  return (
    <div>
      <NewsHeader
        dropdownCategoryOpened={dropdownCategoryOpened}
        setDropdownCategoryOpened={setDropdownCategoryOpened}
        dropdownMenuOpened={dropdownMenuOpened}
        setDropdownMenuOpened={setDropdownMenuOpened}
        handleSearchInput={handleSearchInput}
        fetchAllData={fetchAllData}
        openInsertModal={openInsertModal}
        openImportModal={openImportModal}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      {filterNewsData.length > 0
        ? filterNewsData.map((d) => {
            const allCoins = d.coins.replace(/\s/g, "").split(",");
            return (
              <NewsCard key={d.title}>
                <HeaderContainer>
                  <NewHeadlineText>{d.source.toUpperCase()}:</NewHeadlineText>
                  <DateTime>
                    {new Date(Number(d.timestamp)).toISOString()}
                  </DateTime>
                </HeaderContainer>
                <NewsTitle
                  href={d.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {d.title}
                </NewsTitle>
                <HeaderContainer>
                  <ButtonContainer>
                    {allCoins.map((coin) => {
                      return (
                        <CoinButton
                          key={coin}
                          onClick={() =>
                            handleNewsButton(
                              `${coin.toUpperCase()}USDT`,
                              d.timestamp
                            )
                          }
                        >
                          <span>{coin}</span>
                        </CoinButton>
                      );
                    })}
                  </ButtonContainer>
                </HeaderContainer>
              </NewsCard>
            );
          })
        : null}
      <ImportModal
        isImportModalOpen={isImportModalOpen}
        closeImportModal={closeImportModal}
        fetchAllData={fetchAllData}
      />
      <InsertModal
        isInsertModalOpen={isInsertModalOpen}
        closeInsertModal={closeInsertModal}
        setNewsData={setNewsData}
        newsData={newsData}
      />
    </div>
  );
};

export default News;
