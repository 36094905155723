import ReactModal from "react-modal";
import {
  InsertContainer,
  InsertContainerLine,
  InsertForm,
  InsertInput,
  LabelContainer,
  RowContainer,
} from "./insertStyle";
import { CoinButton } from "../news/newsStyle";
import { DbDataInsert, InsertFieldProps } from "../utils/interface";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { categoryOptions } from "../utils/utils";
import { inputColor } from "../utils/color";
import React from "react";

const InsertField = ({
  isInsertModalOpen,
  closeInsertModal,
  handleInsertDataSubmit,
  pairHeaders,
  handleInsertDataChange,
  setUserInsertDataInput,
  userInsertDataInput,
  headersObject,
  categorySubmit,
  setCategorySubmit,
}: InsertFieldProps) => {
  return (
    <>
      <ReactModal
        isOpen={isInsertModalOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        className="modal"
        overlayClassName="overlay"
        onRequestClose={closeInsertModal}
        contentLabel="Insert Modal"
        closeTimeoutMS={400}
      >
        <InsertContainer>
          <h4 style={{ marginBottom: 30 }}>Insert News Headlines</h4>
          <InsertForm onSubmit={handleInsertDataSubmit}>
            {pairHeaders.map((pairs, index) => {
              return (
                <InsertContainerLine key={index}>
                  {pairs.map((header, index) => (
                    <div key={`${index}-${header}`}>
                      <RowContainer>
                        <LabelContainer>{header}: </LabelContainer>
                        {header === "Category" ? (
                          <Autocomplete
                            disablePortal
                            value={categorySubmit}
                            onChange={(event: any, newValue: string | null) => {
                              setCategorySubmit(newValue);
                              const category = "category";
                              const categoryNumber = newValue?.split(".")[0];
                              setUserInsertDataInput({
                                ...userInsertDataInput,
                                [category]: categoryNumber || "",
                              });
                            }}
                            id="insert-category"
                            options={categoryOptions}
                            PaperComponent={({ children }) => (
                              <Paper
                                style={{
                                  background: inputColor,
                                }}
                              >
                                {children}
                              </Paper>
                            )}
                            sx={{
                              width: 250,
                              border: "none",
                              height: 40,
                              backgroundColor: inputColor,
                              display: "flex",
                              alignItems: "center",
                              borderRadius: 1,
                              marginLeft: 2.5,
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: 14,
                                },
                              },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        ) : (
                          <InsertInput
                            type={
                              header.toLowerCase() === "timestamp"
                                ? "number"
                                : "text"
                            }
                            name={header}
                            placeholder={`Enter ${header}`}
                            value={
                              userInsertDataInput[
                                headersObject[header] as keyof DbDataInsert
                              ]
                            }
                            required={header.toLowerCase() !== "info"}
                            onChange={handleInsertDataChange}
                          />
                        )}
                      </RowContainer>
                    </div>
                  ))}
                </InsertContainerLine>
              );
            })}
            <CoinButton insertModal={true} type="submit">
              <span>Save</span>
            </CoinButton>
          </InsertForm>
        </InsertContainer>
      </ReactModal>
    </>
  );
};

export default React.memo(InsertField);
