import { DropdownBox, DropdownItems, MenuButton } from "./dropdownStyle";
import { DropDownMenuProps } from "../utils/interface";
import React from "react";

const DropdownMenuOptions = ({
  openInsertModal,
  openImportModal,
}: DropDownMenuProps) => {
  return (
    <>
      <DropdownItems>
        <DropdownBox import>
          <ul>
            <MenuButton
              isSelected={false}
              key={"import"}
              onClick={() => {
                openImportModal && openImportModal();
              }}
              optionMenu={true}
            >
              <li>Import File</li>
            </MenuButton>
            <MenuButton
              isSelected={false}
              key={"insert"}
              onClick={() => {
                openInsertModal && openInsertModal();
              }}
              optionMenu={true}
            >
              <li>Insert News</li>
            </MenuButton>
          </ul>
        </DropdownBox>
      </DropdownItems>
    </>
  );
};

export default React.memo(DropdownMenuOptions);
