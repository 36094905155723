import { useEffect, useRef } from "react";
import { BiCategoryAlt } from "react-icons/bi";
import DropdownCategory from "../dropdown/dropdownCategory";
import { CategoryMenuProps } from "../utils/interface";
import { categoryOptions } from "../utils/utils";

const CategoryMenu = ({
  dropdownCategoryOpened,
  setDropdownCategoryOpened,
  dropdownMenuOpened,
  setDropdownMenuOpened,
  selectedItems,
  setSelectedItems,
}: CategoryMenuProps) => {
  const dropdownCategoryRef = useRef<HTMLDivElement>(null);

  const handleCategorySelected = (category: string) => {
    if (selectedItems.includes(category)) {
      setSelectedItems(selectedItems.filter((item) => item !== category));
    } else {
      setSelectedItems([...selectedItems, category]);
    }
  };

  const toggleCategoryDropdown = () => {
    if (dropdownMenuOpened) setDropdownMenuOpened(false);
    setDropdownCategoryOpened(!dropdownCategoryOpened);
  };

  //for most updated data (call api everytime category selected)
  // const sendCategoryRequest = useCallback(async () => {
  //   try {
  //     if (selectedItems.length === 0) {
  //       setNewsData && setNewsData([]);
  //       toast.warn(`No category selected. Please select a category`);
  //       return;
  //     }
  //     const response: EndpointDataResponse = await filterCategory(
  //       selectedItems
  //     );
  //     console.log("selected category: ", selectedItems);
  //     if (!response || !response.success || response.data.length === 0) {
  //       console.log("category response: ", response);
  //       toast.error(response.message);
  //     } else {
  //       setNewsData && setNewsData(response.data);
  //     }
  //   } catch (err) {
  //     console.error("Error sending category request: ", err);
  //     return;
  //   }
  // }, [selectedItems, setNewsData]);

  // useEffect(() => {
  //   sendCategoryRequest();
  // }, [sendCategoryRequest]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropdownCategoryRef.current &&
        !dropdownCategoryRef.current.contains(e.target as Node)
      ) {
        setDropdownCategoryOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setDropdownCategoryOpened]);

  return (
    <>
      <div style={{ position: "relative" }} ref={dropdownCategoryRef}>
        <BiCategoryAlt
          onClick={toggleCategoryDropdown}
          style={{ marginTop: 10 }}
        />
        {dropdownCategoryOpened ? (
          <DropdownCategory
            categoryOptions={categoryOptions}
            selectedItems={selectedItems}
            handleCategorySelected={handleCategorySelected}
          />
        ) : null}
      </div>
    </>
  );
};

export default CategoryMenu;
