import React from "react";
import { DropdownCategoryProps } from "../utils/interface";
import {
  CheckMark,
  DropdownBox,
  DropdownItems,
  MenuButton,
} from "./dropdownStyle";

const DropdownCategory = ({
  categoryOptions,
  selectedItems,
  handleCategorySelected,
}: DropdownCategoryProps) => {
  return (
    <DropdownItems>
      <DropdownBox>
        <ul>
          {categoryOptions.map((category) => {
            const isSelected = selectedItems.includes(category);
            return (
              <MenuButton
                key={category}
                onClick={() => handleCategorySelected(category)}
                isSelected={isSelected}
              >
                <li>
                  {category} {isSelected ? <CheckMark>✔️</CheckMark> : ""}
                </li>
              </MenuButton>
            );
          })}
        </ul>
      </DropdownBox>
    </DropdownItems>
  );
};

export default React.memo(DropdownCategory);
