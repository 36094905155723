import { toast } from "react-toastify";
import { DbDataInsert, EndpointUploadResponse } from "../utils/interface";

const handleInsertData = async (
  data: DbDataInsert
): Promise<EndpointUploadResponse> => {
  try {
    // console.log("handle insert data: ", data);
    const response = await fetch(String(process.env.REACT_APP_INSERT), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      // const errMessage = await response.json();
      // console.log("response insert json: ", errMessage);
      toast.error(`${response.status} ${response.statusText}`);
      throw new Error(
        `Error getting insert response: ${response.status} ${response.statusText}`
      );
    }
    return response.json();
  } catch (err) {
    console.error("Error handling insert data: ", err);
    throw err;
  }
};

export default handleInsertData;
