import ReactModal from "react-modal";
import { ImportModalProps } from "../utils/interface";
import "./modal.css";
import DragAndDrop from "../dragNDrop/dragNDrop";
import React from "react";

const ImportModal = ({
  isImportModalOpen,
  closeImportModal,
  fetchAllData,
}: ImportModalProps) => {
  return (
    <>
      <ReactModal
        isOpen={isImportModalOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        className="modal"
        overlayClassName="overlay"
        onRequestClose={closeImportModal}
        contentLabel="Import Modal"
        closeTimeoutMS={400}
      >
        <DragAndDrop
          closeImportModal={closeImportModal}
          fetchAllData={fetchAllData}
        />
      </ReactModal>
    </>
  );
};

export default React.memo(ImportModal);
