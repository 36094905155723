import { IoReload } from "react-icons/io5";
import {
  HeaderContainer,
  IconsMenu,
  MenuPosition,
  SearchBar,
  TitleText,
} from "./newsStyle";
import CategoryMenu from "./category";
import MenuDropdown from "./menuDropdown";
import { NewsHeaderProps } from "../utils/interface";
import { textColor } from "../utils/color";
import React from "react";

const NewsHeader = ({
  dropdownCategoryOpened,
  setDropdownCategoryOpened,
  dropdownMenuOpened,
  setDropdownMenuOpened,
  handleSearchInput,
  fetchAllData,
  openInsertModal,
  openImportModal,
  selectedItems,
  setSelectedItems,
}: NewsHeaderProps) => {
  return (
    <>
      <HeaderContainer>
        <MenuPosition>
          <TitleText>Important News</TitleText>
          <IoReload
            onClick={fetchAllData}
            style={{
              color: textColor,
              marginLeft: 13,
              fontSize: 20,
              marginBottom: 5,
              cursor: "pointer",
            }}
          />
        </MenuPosition>
        <MenuPosition>
          <SearchBar
            type="text"
            placeholder="Search 🔎"
            onChange={(e) =>
              handleSearchInput && handleSearchInput(e.target.value)
            }
          />
          <IconsMenu>
            <CategoryMenu
              dropdownCategoryOpened={dropdownCategoryOpened}
              setDropdownCategoryOpened={setDropdownCategoryOpened}
              dropdownMenuOpened={dropdownMenuOpened}
              setDropdownMenuOpened={setDropdownMenuOpened}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
            <MenuDropdown
              dropdownCategoryOpened={dropdownCategoryOpened}
              setDropdownCategoryOpened={setDropdownCategoryOpened}
              dropdownMenuOpened={dropdownMenuOpened}
              setDropdownMenuOpened={setDropdownMenuOpened}
              openInsertModal={openInsertModal}
              openImportModal={openImportModal}
            />
          </IconsMenu>
        </MenuPosition>
      </HeaderContainer>
    </>
  );
};

export default React.memo(NewsHeader);
