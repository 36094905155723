import styled from "@emotion/styled/macro";
import { inputColor, textColor } from "../utils/color";

export const InsertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${textColor};
  height: 500px;
`;

export const InsertContainerLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const InsertForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InsertInput = styled.input`
  width: 250px;
  height: 40px;
  padding: 10px;
  border: none;
  background-color: ${inputColor};
  margin: 20px;
  box-sizing: border-box;
  border-radius: 5px;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabelContainer = styled.div`
  text-align: right;
  width: 125px;
  margin-right: 10px;
`;

export const InputPosition = styled.div`
  flex: 1;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
