import styled from "@emotion/styled/macro";
import {
  buttonBorderColor,
  inputColor,
  selectedIntervalColor,
} from "../utils/color";

export const TickerInput = styled.input`
  width: 250px;
  height: 40px;
  padding: 20px;
  align-items: center;
  background-color: ${inputColor};
  box-sizing: border-box;
  border: none;
  font-weight: 600;
  font-size: 15px;

  &:focus {
    outline: none;
  }
`;

export const TickerSubmitButton = styled.button`
  width: 100px;
  height: 30px;
  cursor: pointer;
  background-color: ${inputColor};
  border: 1px solid ${buttonBorderColor};
  margin-right: 20px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 15px;
  &:hover {
    background-color: ${selectedIntervalColor};
  }
`;

export const HomepageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  background-color: black;
`;

export const Chart = styled.div`
  width: 700px;
  height: 650px;
  z-index: 1;
`;

export const ChartContainer = styled.div`
display: flex:
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 700px;
  background-color: ${inputColor};
  position: relative;
`;

export const IntervalButton = styled.button<{ selected: boolean }>`
  width: 50px;
  height: 40px;
  font-weight: 600;
  font-size: 15px;
  background-color: ${(props) =>
    props.selected ? selectedIntervalColor : inputColor};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${selectedIntervalColor};
  }
`;
