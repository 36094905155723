import { useState } from "react";
import ChartComponent from "../chart/candlestickChart";
import News from "../news/news";
import { HomePageStyle, LeftContainer, RightContainer } from "./homepageStyle";
import { CandleStickData } from "../utils/interface";

const Homepage = () => {
  const defaultCoin = "btcusdt";
  const [coin, setCoin] = useState<string>(defaultCoin);
  const [timestamp, setTimestamp] = useState<string>("");
  const [klineData, setKlineData] = useState<CandleStickData[]>([]);
  const [isAutoComplete, setIsAutoComplete] = useState<boolean>(false);

  const handleNewsButton = (ticker: string, timestamp: string) => {
    setKlineData([]);
    if (isAutoComplete) setIsAutoComplete(false);
    const thousandsCoins = [
      "1000SHIBUSDT",
      "1000XECUSDT",
      "1000LUNCUSDT",
      "1000PEPEUSDT",
      "1000FLOKIUSDT",
      "1000BONKUSDT",
      "1000SATSUSDT",
      "1000RATSUSDT",
    ];
    const coin =
      thousandsCoins.find((tCoin) => tCoin.includes(ticker)) || ticker;
    setCoin(coin);
    setTimestamp(timestamp);
    // console.log("timestamp: ", timestamp);
  };

  return (
    <>
      <HomePageStyle>
        <LeftContainer>
          <News handleNewsButton={handleNewsButton} />
        </LeftContainer>
        <RightContainer>
          <ChartComponent
            defaultCoin={defaultCoin}
            setCoin={setCoin}
            coin={coin}
            timestamp={timestamp}
            klineData={klineData}
            setKlineData={setKlineData}
            setTimestamp={setTimestamp}
            isAutoComplete={isAutoComplete}
            setIsAutoComplete={setIsAutoComplete}
          />
        </RightContainer>
      </HomePageStyle>
    </>
  );
};

export default Homepage;
