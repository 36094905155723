import { useCallback, useEffect, useState } from "react";
import { BinacneKlineData } from "../utils/interface";
import { UTCTimestamp } from "lightweight-charts";
import { toast } from "react-toastify";
import { getStartEndTime } from "../utils/utils";

const BinanceKlineData = ({
  setKlineData,
  coin,
  interval,
  timestamp,
}: BinacneKlineData) => {
  const [rawData, setRawData] = useState<number[][]>([]);

  const fetchData = useCallback(
    async (signal: AbortSignal) => {
      try {
        if (!coin || !interval) return;
        // console.log("fetch interval: ", interval);
        // console.log("fetch timestamp: ", timestamp);
        const { startTime, endTime } = getStartEndTime(interval, timestamp);
        // console.log("fetch start end: ", timestamp, startTime, endTime);
        const url = timestamp
          ? `${
              process.env.REACT_APP_BINANCE_KLINE
            }?limit=1500&interval=${interval}&symbol=${coin.toUpperCase()}&startTime=${startTime}&endTime=${endTime}`
          : `${
              process.env.REACT_APP_BINANCE_KLINE
            }?limit=1500&interval=${interval}&symbol=${coin.toUpperCase()}`;
        const response = await fetch(url, { signal });
        if (!response.ok) {
          toast.error(
            `Invalid Ticker: ${response.status} ${response.statusText}`
          );
          throw new Error(`Error fetching data ${response}`);
        }
        const data: number[][] = await response.json();
        setRawData(data);
      } catch (err) {
        console.error("Error fetching data: ", err);
      }
    },
    [coin, interval, timestamp]
  );

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchData(signal);

    return () => {
      abortController.abort();
    };
  }, [fetchData]);

  useEffect(() => {
    const formattedData = rawData.map((item) => ({
      time: Math.floor(Number(item[0]) / 1000) as UTCTimestamp,
      open: Number(item[1]),
      high: Number(item[2]),
      low: Number(item[3]),
      close: Number(item[4]),
    }));
    setKlineData(formattedData);
  }, [rawData, setKlineData]);

  return <div></div>;
};

export { BinanceKlineData };
