import styled from "@emotion/styled/macro";
import { backgroundColor } from "../utils/color";

export const HomePageStyle = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${backgroundColor};
  display: flex;
  aligns-items: center;
  justitfy-content: center;
`;

export const LeftContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
