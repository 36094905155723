import styled from "@emotion/styled/macro";
import { DragAndDropProps } from "../utils/interface";
import { textColor } from "../utils/color";

const getColor = (props: DragAndDropProps) => {
  if (props.isDragAccept) {
    return "#00e676";
  } else if (props.isDragReject) {
    return "#ff1744";
  } else if (props.isFocused) {
    return "#2196f3";
  } else {
    return "#eeeeee";
  }
};

export const DragAndDropContainer = styled.div<DragAndDropProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #1d2021;
  color: ${textColor};
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  width: 600px;
`;

export const DragAndDropPosition = styled.div<{ parent?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${textColor};
  height: ${(props) => (props.parent ? "60%" : "")};

  span {
    margin-top: 20px;
  }
  ul {
    list-style: none;
  }
`;
