import { useCallback, useEffect, useState } from "react";
import {
  BinanceTickerData,
  BinanceTickerSymbol,
  TickerDropDownProps,
} from "../utils/interface";
import { toast } from "react-toastify";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { inputColor } from "../utils/color";

const TickerInputDropdown = ({
  value,
  setInputValue,
  setCoin,
  coin,
  setTimestamp,
  isAutoComplete,
  setIsAutoComplete,
}: TickerDropDownProps) => {
  const [tickerData, setTickerData] = useState<BinanceTickerSymbol[]>([]);

  const handleAutoComplete = (coin: string | null) => {
    if (isAutoComplete) setTimestamp("");
    setCoin(coin || "");
  };

  const fetchTickerInfo = useCallback(async (signal: AbortSignal) => {
    try {
      const response = await fetch(
        String(process.env.REACT_APP_BINANCE_TICKER_INFO),
        { signal }
      );
      // console.log("response: ", response);
      if (!response.ok) {
        toast.error(`${response.status} ${response.statusText}`);
        throw new Error("Error getting tickers info");
      }
      const tickerResponseData: BinanceTickerData = await response.json();
      // console.log("ticker data: ", tickerResponseData);

      setTickerData(
        tickerResponseData.symbols.filter((coin) => !coin.symbol.includes("_"))
      );
    } catch (err) {
      console.error("Failed fetching ticker info: ", err);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchTickerInfo(signal);

    return () => {
      abortController.abort();
    };
  }, [fetchTickerInfo]);

  return (
    <Autocomplete
      value={coin.toUpperCase()}
      onChange={(event: any, newValue: string | null) => [
        handleAutoComplete(newValue || ""),
      ]}
      inputValue={value.toUpperCase()}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setIsAutoComplete(true);
      }}
      id="ticker"
      options={tickerData.map((symbol) => symbol.symbol)}
      PaperComponent={({ children }) => (
        <Paper style={{ background: inputColor, fontWeight: "bold" }}>
          {children}
        </Paper>
      )}
      sx={{
        width: 250,
        height: 40,
        alignItems: "center",
        display: "flex",
        border: "none",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
          "& .MuiInputBase-input": {
            fontWeight: "bold",
          },
        },
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default TickerInputDropdown;
